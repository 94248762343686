import { FC } from "react";

import { useModal } from "@/components/Table/hooks/useModal";
import { OrderActionModal } from "../common/template/orderActionModal";
import { FlagOderProps } from "./types";
import { useOptions } from "../hooks/useOptions";
import { usePostOrderAction } from "../hooks/usePostOrderAction";

export const FlagOrder: FC<FlagOderProps> = ({ recordID }) => {
  const options = useOptions("api:internal-orders-flag", recordID);
  const flagOrder = usePostOrderAction("api:internal-orders-flag");

  const { modal, handleOpenModal } = useModal(OrderActionModal, {
    recordID,
    header: "Flag Order",
    saveButtonLabel: "Flag this Order",
    hasDispositionForm: true,
    onClick: flagOrder,
    options,
  });

  return (
    <>
      <button className="drop-down-button" onClick={handleOpenModal}>
        Flag Order
      </button>
      {modal}
    </>
  );
};
