import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { useContext } from "react";

import { TOAST_PROPS } from "@/components/Table/components/TableNavbar/utils/downloadTableData/constants";
import { TableContext } from "@/components/Table/context";
import { getURL } from "@/utils/getURL";

const cookies = new Cookies();

export const usePostOrderAction = (url: string) => {
  const { handleReloadTable } = useContext(TableContext);
  const orderURL = url;

  return async (data: { reason: number; notes: string }, recordID: number) => {
    const url: string = getURL(orderURL, {
      pk: recordID.toString(),
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookies.get("csrftoken"),
        },
      });

      const { detail } = await response.json();
      if (!response.ok) return toast.error(detail, TOAST_PROPS);
      toast.success(detail, TOAST_PROPS);
      handleReloadTable?.();
    } catch (error: any) {
      console.error(error, "error");
      toast.error(
        error.details || "Action failed, please try again later",
        TOAST_PROPS
      );
    }
  };
};
