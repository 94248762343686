import dispositionForm from "@root/bloodbuy/assets/files/bloodbuy_disposition_form.pdf";
import { OrderDispositionFormProps } from "./types";

export const OrderDispositionForm = ({
  hasDispositionForm,
}: OrderDispositionFormProps) =>
  hasDispositionForm ? (
    <p className="orders-actions-modal-text">
      Supplier may require you to submit a disposition form to process credits
      for non transfusable units.{" "}
      <a href={dispositionForm} target="_blank" rel="noopener noreferrer">
        Download a disposition form
      </a>
    </p>
  ) : null;
