import { StatusRangeConfig } from "./types";

// Define a configuration map for status ranges
export const STATUS_CONFIG_MAP: StatusRangeConfig[] = [
  {
    range: [200, 299],
    defaultMessage: "Package notified",
    type: "success",
  },
  {
    range: [400, 499],
    defaultMessage: "Unknown client error",
    type: "error",
  },
  {
    range: [500, 599],
    defaultMessage: "Unknown server error",
    type: "error",
  },
];
