import { MdKeyboardArrowDown } from "react-icons/md";

import { DropDownSingleSelectProps } from "./types";
import { DropdownMenu } from "../DropdownMenu";
import { Icon } from "../Icon";
import { useOpen } from "./hooks/useOpen";

import "./scss/index.scss";

export const DropdownSingleSelect = <T,>({
  options,
  header,
  selectedOption,
  placeholder,
  size,
  className,
  ...dropdownMenuProps
}: DropDownSingleSelectProps<T>) => {
  const { open, toggleDropdown, dropdownRef } = useOpen();
  const buttonClassName: string = `single-select-dropdown ${
    open ? "open" : ""
  }`;

  const optionFoundByValue = options.find(
    ({ value }) => value === selectedOption
  );
  const currentSelectedOption = optionFoundByValue
    ? optionFoundByValue.title
    : selectedOption;

  const thereAreNoOptions: boolean = !options.length;

  const title: string | undefined | number = thereAreNoOptions
    ? "No options"
    : currentSelectedOption || placeholder || "Select an option";

  return (
    <div
      data-testid="single-select-dropdown"
      className={`single-select-dropdown-container ${className} ${size}`}
    >
      {header ? <strong key={header}>{header}</strong> : null}
      <button
        ref={dropdownRef}
        onClick={thereAreNoOptions ? undefined : toggleDropdown}
        className={buttonClassName}
      >
        <span className="title">{title}</span>
        <Icon color="neutral" icon={MdKeyboardArrowDown} state={"link"} />
      </button>
      {open && !thereAreNoOptions ? (
        <DropdownMenu {...{ options, selectedOption, ...dropdownMenuProps }} />
      ) : null}
    </div>
  );
};
