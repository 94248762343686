import { ReactElement } from "react";

/**
 * Generates tooltip content as a string from a given React element.
 *
 * This function extracts the `value` prop from the provided `cellValue` React element.
 * It handles cases where the element has:
 * - A direct `value` prop.
 * - Multiple child elements, each with their own `value` prop.
 * - A single child element with a `value` prop.
 *
 * @function generateToolTipContent
 * @param {ReactElement} cellValue - The React element representing the cell value.
 * @returns {string} The tooltip content extracted from the cell value.
 *
 * @example <caption>Element with direct value prop</caption>
 * const cellValue = <SomeComponent value="Tooltip Content" />;
 * const tooltip = generateToolTipContent(cellValue);
 * // tooltip === "Tooltip Content"
 *
 * @example <caption>Element with multiple children</caption>
 * const cellValue = (
 *   <div>
 *     <ChildComponent value="First" />
 *     <ChildComponent value="Second" />
 *   </div>
 * );
 * const tooltip = generateToolTipContent(cellValue);
 * // tooltip === "First - Second"
 *
 * @example <caption>Element with a single child</caption>
 * const cellValue = (
 *   <div>
 *     <ChildComponent value="Only Child" />
 *   </div>
 * );
 * const tooltip = generateToolTipContent(cellValue);
 * // tooltip === "Only Child"
 */
export function generateToolTipContent(cellValue: ReactElement): string {
  const cellProps = cellValue?.props;

  // If the element has a direct 'value' prop, return it.
  if (cellProps?.hasOwnProperty("value")) return cellProps.value;

  const cellChildrens = cellProps?.children;

  // If the element has multiple children, extract their 'value' props and join them.
  if (cellChildrens && cellChildrens.length > 1)
    return cellChildrens
      .flat()
      .filter(Boolean) // Filter out undefined or null values
      .map((child: ReactElement) => child?.props?.children)
      .join(" - ");

  // If there's a single child, return its 'value' prop or an empty string.
  return cellProps?.children?.[0]?.props?.value ?? "";
}
