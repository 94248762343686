import { useEffect, useState, useMemo } from "react";

import { validateUrl } from "./utils/validateURL";
import { validateResponse } from "./utils/validateResponse";
import { HeadersType, UseFetch, useFetchProps } from "./types";

const DEFAULT_DATA = {
  results: null,
  error: undefined,
  count: 0,
  next: undefined,
  previous: undefined,
};

/**
 * Hook for fetching table records
 *
 * @param url - URL to fetch data from
 * @param pageSize - The current page size
 * @param currentPage - Current page to fetch the data
 * @param sortBy - The value from which the data will be sorted
 * @param input - Input value to filter the API response
 */
export const useFetch = <T>({
  url,
  currentPage,
  filters,
  counter,
  setIsTableLoading,
}: useFetchProps): UseFetch<T> => {
  const headers: HeadersType = useMemo(
    () => ({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    }),
    []
  );

  const [data, setData] = useState<UseFetch<T>>(DEFAULT_DATA);

  useEffect(() => {
    const abortController = new AbortController();

    const loadingID = setTimeout(() => setIsTableLoading?.(true), 1000);

    const fetchData = async () => {
      try {
        validateUrl(url);
        const response: Response = await fetch(url, {
          ...headers,
          signal: abortController.signal,
        });
        const responseBody: UseFetch<T> = await response.json();
        validateResponse(responseBody, response);
        setData(responseBody);
        setIsTableLoading?.(false);
      } catch (error: any) {
        //Ignore abort message (stops multiple calls to the same endpoint)
        if (error.name === "AbortError") return;
        setData({ error: error.message, count: 0 });
      } finally {
        clearTimeout(loadingID);
      }
    };

    fetchData();
    return () => abortController.abort();
  }, [url, currentPage, filters, headers, setIsTableLoading, counter]);

  return data;
};
