import { FC } from "react";
import { PiDotsThreeBold } from "react-icons/pi";

import { useDetectClickOutside } from "@/components/Table/hooks/useDetectClickOutside";
import { GenerateActions } from "./components/GenerateActions";
import { Icon } from "@/components/Icon";
import { TableRowActionsProps } from "./types";

import "./scss/index.scss";

export const TableRowActions: FC<TableRowActionsProps> = ({
  actions,
  record,
}) => {
  const { clickedOutside, ref, clickedOutsideToggle } = useDetectClickOutside({
    detectClickInside: true,
  });

  return (
    <tr className="table-row__actions-container">
      <td className="table-row__actions-container__table-cell">
        <div className="table-row__actions-container__table-cell-sub" ref={ref}>
          <Icon
            onClick={clickedOutsideToggle}
            icon={PiDotsThreeBold}
            color={"neutral"}
            size={"x-small"}
            state={"link"}
            colorClassName="actions-icon"
            type="button"
          />
          <GenerateActions {...{ actions, record, click: clickedOutside }} />
        </div>
      </td>
    </tr>
  );
};
