import { useContext } from "react";

import { useModal } from "@/components/Table/hooks/useModal";
import { EmailProps } from "./types";
import { EmailTemplate } from "./template/EmailTemplate";
import { TableContext } from "@/components/Table/context";

import "./scss/index.scss";

export const Email = <T extends Record<string, any>>({
  record,
  config,
}: EmailProps<T>) => {
  const { modal, handleOpenModal } = useModal(EmailTemplate, {
    ...record,
    ...config,
  });

  const { checkboxes, checkboxesHandler } = useContext(TableContext);

  const onClick = () => {
    //Reset BulkSelection
    checkboxes?.length && checkboxesHandler?.([]);
    handleOpenModal();
  };

  return (
    <>
      <button className="drop-down-button" onClick={onClick}>
        Email
      </button>
      {modal}
    </>
  );
};
