import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { FacilityAdminActiveType } from "./types/facilityAdminActive";
import { useFilters } from "@/hooks/useFilters";
import { useModal } from "@/components/Table/hooks/useModal";
import { EmailTemplate } from "@/components/Table/components/BaseTable/hooks/useTableRows/components/TableRowItem/components/TableRowActions/components/GenerateActions/components/GetAction/components/Email/template/EmailTemplate";
import { TableHeaders } from "@/components/Table/types/TableHeaders";

export const FacilityAdminActive: FC = () => {
  const headers: TableHeaders = [
    { title: "FACILITY", apiParam: "name" },
    { title: "LOCATION", apiParam: "location" },
    { title: "TYPE", apiParam: "facility_type" },
    {
      title: "PRIMARY CONTACT",
      apiParam: ["primary_contact", "full_name"],
    },
    { title: "USERS", apiParam: "user_count" },
    { title: "GROUP ADMINS", apiParam: "group_admin_count" },
  ];

  const url: string = getURL("api:internal-facilities-list");
  const filtersURL: string = getURL("api:internal-facilities-schema-options");
  const filterOptions = useFilters(filtersURL);
  const { modal, handleOpenModal } = useModal(EmailTemplate);

  return (
    <>
      {modal}
      <Table<FacilityAdminActiveType>
        bulkActions={{
          primaryAction: {
            label: "Email",
            onClick: handleOpenModal,
            config: {
              type: "facility",
            },
          },
        }}
        id={""}
        navbar={{
          header: "Active Facilities",
          input: true,
        }}
        headers={headers}
        url={url}
        filters={{
          ...filterOptions,
          config: {
            "Facility": "singleSelectSearchable",
            "State": "singleSelectSearchable",
          },
        }}
        actions={[
          "view_profile",
          "edit_facility",
          { current: "email", config: { type: "facility" } },
        ]}
      />
    </>
  );
};
