import Cookies from "universal-cookie";

import { createToastNotifier } from "./utils/createToastNotifier";
import { getURL } from "@/utils/getURL";

const cookies = new Cookies();

export const createPackageNotification = async (packageID: string) => {
  const toastUpdate = createToastNotifier("Package notification");

  const url = getURL("api:internal-packages-send-notifications", {
    pk: packageID,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRFToken": cookies.get("csrftoken"),
      },
    });

    const status = response.status;
    const { message, title }: { message?: string; title?: string } =
      await response.json();

    toastUpdate({ status, message: message || title });
  } catch (error) {
    console.error(error);
    toastUpdate({
      status: 500,
      message: "Unknown error, please try again later",
    });
  }
};
