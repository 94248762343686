import { Button } from "@/components/Button";
import { OrderButtonsProps } from "./types";

export const OrderButtons = ({
  onCloseModal,
  backButtonLabel = "Cancel",
  isMultiReason,
  saveButtonLabel = "Save",
  saveButtonColor = "primary",
  orderID,
  selectedOption,
  onClick,
}: OrderButtonsProps) => (
  <div className="buttons-container">
    <Button
      testID="order-cancel-button"
      onClick={onCloseModal}
      size={"medium"}
      color={"neutral"}
      state={"link"}
      label={backButtonLabel}
    />
    <Button
      testID="order-save-button"
      disabled={
        selectedOption.reason === null ||
        (isMultiReason && !selectedOption.notes)
      }
      size={"medium"}
      color={saveButtonColor}
      onClick={() => {
        onClick?.(selectedOption as { reason: number; notes: string }, orderID);
        onCloseModal();
      }}
      state={"primary"}
      label={saveButtonLabel}
    />
  </div>
);
