import { ToastConfig } from "../../types";
import { STATUS_CONFIG_MAP } from "./constants";

export const getToastConfig = (
  status: number,
  message?: string
): ToastConfig => {
  const config = STATUS_CONFIG_MAP.find(
    ({ range: [minRange, maxRange] }) =>
      status >= minRange && status <= maxRange
  );

  if (!config) return { message: message || "Unknown error", type: "error" };

  return {
    message: message || config.defaultMessage,
    type: config.type,
  };
};
