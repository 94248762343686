import { useState } from "react";

export const useSelectedOption = () => {
  const [selectedOption, setSelectedOption] = useState<{
    reason: number | null;
    notes: string;
  }>({
    reason: null,
    notes: "",
  });

  const handleSelectedOption = (name: string, option: string | number) => {
    setSelectedOption({ ...selectedOption, [name]: option });
  };

  return {
    selectedOption,
    handleSelectedOption,
  };
};
