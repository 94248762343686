import { toast } from "react-toastify";

import { ToastNotificationOptions } from "./types";
import { getToastConfig } from "./utils/getToastConfig";
import { TOAST_CONFIG } from "./constants/index";

/**
 * @param id - id used to identify the toast to be updated
 * @returns a function that updates the toast
 */
export const createToastNotifier = (id: string) => {
  const toastID = toast.loading(id);

  return ({ status, message, autoClose }: ToastNotificationOptions) => {
    const { message: render, type } = getToastConfig(status, message);

    return toast.update(toastID, {
      ...TOAST_CONFIG,
      render,
      type,
      ...(autoClose && { autoClose }),
    });
  };
};
