import { FC } from "react";

import { IDType } from "../types";
import { createPackageNotification } from "./api/createPackageNotification";

/**
 * Edit a standing order in the system
 *
 * @param packageID - Current package ID
 */
export const PackageNotification: FC<IDType> = ({ recordID: packageID }) => (
  <button
    className="drop-down-button"
    onClick={() => createPackageNotification(packageID.toString())}
  >
    Send notification
  </button>
);
