import { FC } from "react";

import { IDType } from "../types";

/**
 *  Progressing an order in the system
 *
 * @param ID - Order ID
 */
export const ProgressOrder: FC<IDType> = ({ recordID: orderID }) => (
  <a href={`/internal/progress-order/${orderID}`}>Progress Order</a>
);
