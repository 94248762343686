import { LeftAlignment } from "./components/LeftAlignment";
import { RightAlignment } from "./components/RightAlignment";
import { DropdownLabelProps } from "./types";
import { compareValues } from "./utils/compareValues";

import "./scss/index.scss";

export const DropdownLabel = <T,>({
  title,
  rightAlignment,
  onClick,
  value,
  selectedOption,
  customOnClick,
  ...icons
}: DropdownLabelProps<T>) => {
  return (
    <button
      className={`drop-down-button ${
        compareValues(title, selectedOption) ||
        compareValues(value, selectedOption)
          ? "active"
          : ""
      } ${rightAlignment ? "right-alignment" : ""}`}
      onClick={() =>
        customOnClick
          ? customOnClick?.({ value: value ?? (title as T), title })
          : onClick?.({ value: value ?? (title as T), title })
      }
    >
      {rightAlignment ? (
        <RightAlignment {...{ title, ...icons }} />
      ) : (
        <LeftAlignment {...{ title, ...icons }} />
      )}
    </button>
  );
};
