import { Badge } from "@/components/Badge";
import { BadgeColor, BadgeSize, BadgeState } from "@/components/Badge/types";
import { RenderBadgeType } from "./types";

export const renderBadge = ({
  value,
  properties,
}: RenderBadgeType): JSX.Element | null => {
  if (!value) return null;

  const formattedValue: string = value?.toLowerCase().split(" ").join("-");
  const { badgeColor, badgeState, badgeSize } = properties ?? {};

  const color: BadgeColor | undefined = badgeColor?.[formattedValue];
  const size: BadgeSize | undefined = badgeSize?.[formattedValue];
  const state: BadgeState | undefined = badgeState?.[formattedValue];

  return (
    <Badge
      label={value}
      size={size || "small"}
      color={color || "neutral"}
      state={state || "secondary"}
    />
  );
};
