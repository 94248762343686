import { FC } from "react";

import { IDType } from "../types";

/**
 * Edit a standing order in the system
 *
 * @param standingOrderID - Standing Order ID
 */
export const EditStandingOrder: FC<IDType> = ({
  recordID: standingOrderID,
}) => (
  <a
    href={`/internal/standing-order-crud-form/?standing_order_pk=${standingOrderID}&action=edit`}
  >
    Edit standing order
  </a>
);
