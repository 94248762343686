import { TableRowHeadersProps } from "./types";
import { useTruncate } from "@/components/Table/hooks/useTruncate";
import { generateToolTipContent } from "./utils/generateTooltipContent";
import { getCellValue } from "./utils/getCellValue";

import "./scss/index.scss";

export const TableRowHeaders = ({
  rowHeaders,
  record,
}: TableRowHeadersProps) => {
  const { registerElement, truncatedStates } = useTruncate({
    accessChild: true,
  });

  return rowHeaders.map(({ apiParam, ...props }, index) => {
    const cellValue: JSX.Element = getCellValue({
      record,
      apiParam,
      ...props,
    });

    return (
      <td key={index} className="table-row__cell">
        <div
          data-tooltip-content={generateToolTipContent(cellValue)}
          data-tooltip-id={
            truncatedStates[index] ? "table-row-cell-tooltip" : ""
          }
          ref={registerElement(index)}
          className={`table-row__header`}
        >
          {cellValue}
        </div>
      </td>
    );
  });
};
