import { FC, useContext, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { TableContext } from "@/components/Table/context";
import { ModalTemplateProps, UseModalProps } from "./types";

import "./scss/index.scss";

/**
 * A custom hook that returns an object with a modal component and a function to open the modal.
 *
 * @param ModalTemplate - A React component that represents the content of the modal.
 * @param props - An object with props to pass to the `ModalTemplate` component.
 *
 * @returns An object with two properties:
 *   - `modal`: A JSX element representing the modal component.
 *   - `onOpenModal`: A function to open the modal.
 */
export const useModal = <T extends Record<string, unknown>>(
  ModalTemplate: FC<ModalTemplateProps<T>>,
  props?: Omit<T, "onCloseModal">
): UseModalProps<T> => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCloseModal = () => setIsOpen(false);

  // Ensure `config` includes `onCloseModal`
  const config = useRef<T & { onCloseModal: () => void }>({
    ...props,
    onCloseModal: handleCloseModal,
  } as T & { onCloseModal: () => void });

  const context = useContext(TableContext);

  const handleOpenModal = (
    eventOrConfig?: React.MouseEvent<HTMLButtonElement> | Partial<T>
  ) => {
    config.current = {
      ...config.current,
      ...props,
      ...eventOrConfig,
    };
    setIsOpen(true);
  };

  return {
    modal: isOpen ? (
      <Modal
        focusTrapped={false}
        showCloseIcon={true}
        open={isOpen}
        onClose={handleCloseModal}
        center
      >
        <ModalTemplate
          {...{
            ...context,
            ...config.current,
          }}
        />
      </Modal>
    ) : null,
    handleOpenModal,
  };
};
