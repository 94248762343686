import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { useFilters } from "@/hooks/useFilters";
import { handleCreateStandingOrder } from "../common/utils/handleCreateStandigOrder";

const url: string = getURL("api:internal-standing-orders-paused");
const filtersURL: string = getURL(
  "api:internal-standing-orders-schema-options"
);

export const PausedStandingOrders: FC = () => {
  const filterOptions = useFilters(filtersURL, ["final_order_date"]);

  return (
    <Table
      id={"facility_id"}
      addRecord={handleCreateStandingOrder}
      navbar={{ header: "Paused Standing Orders", input: true }}
      sortBy="next_order"
      headers={[
        { title: "#", apiParam: "id" },
        { title: "ITEMS", apiParam: "component_string" },
        { title: "BUYER", apiParam: "buyer" },
        { title: "SELLER", apiParam: "seller" },
        { title: "REPEATS", apiParam: "frequency" },
        { title: "DAY", apiParam: "day" },
        { title: "NEXT ORDER", apiParam: "next_order", type: "date" },
      ]}
      filters={{ ...filterOptions, allowFutureDates: ["next_order"] }}
      url={url}
      actions={["standing_order_activity", "edit_standing_order"]}
    />
  );
};
