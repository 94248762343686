import { FC } from "react";

import { IDType } from "../types";

/**
 * Modal for order activity
 *
 * @param orderID - Order ID
 */
export const OrderActivity: FC<IDType> = ({ recordID: orderID }) => (
  <a
      href="#"
      className="js-load_changelog_modal"
      data-url={`/orders/load-modal-order-log/`}
      data-id={orderID}
  >
    Order Activity
  </a>
);
