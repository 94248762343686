import { format as dateFormat } from "date-fns";

import { RenderDateType } from "./types";

export const renderDate = ({ value, properties }: RenderDateType) => {
  const { className, format } = properties ?? {};
  const defaultFormat: string = "P";

  if (!value) return null;

  return (
    <div className={className ?? ""}>
      {dateFormat(value, format || defaultFormat)}
    </div>
  );
};
