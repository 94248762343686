import { createContext } from "react";

import { ExpandState } from "./hooks/useTableLogic/hooks/types";
import { TableBulkActions } from "./types/TableBulkActions";
import { FiltersType, TableFilters } from "./types/TableFilters";
import { TableActions } from "./types/TableActions";
import { TableNavbar } from "./types/TableNavbar";
import { TableDropDownProps } from "./types/Table";

type TableContextProps<T> = {
  updateRecord: (value: T, prop: string, id: number) => void;
  areAllCheckboxesSelected: boolean;
  records: Record<string, any>;
  updateFilters: (value: FiltersType) => void;
  toggleCheckbox: (newRecord: T) => void;
  toggleAllCheckboxes: (isChecked: boolean) => void;
  checkboxes: Record<string, any>[];
  bulkActions: TableBulkActions;
  checkboxesHandler: (value: T) => void;
  expandState: ExpandState;
  expandStateHandler: (userID: number) => void;
  resetExpandState: () => void;
  navbar?: TableNavbar;
  filters?: TableFilters;
  dropDown?: TableDropDownProps;
  actions?: TableActions;
  addRecord?: () => void | string;
  isTableLoading: boolean;
  allRecordsCount: number;
  currentApiEndpoint: string;
  updateSearchParams: (value: string, name: string) => void;
  apiParams: string;
  apiVersion: string;
  sortBy: string;
  handleReloadTable: () => void;
};

const defaultValues: Partial<TableContextProps<any>> = {};

export const TableContext = createContext(defaultValues);
