import {
  DropdownMenuProps,
  DropdownOption,
  DropdownOptionProps,
} from "./types";
import { DropdownMenuCheckboxItem } from "./components/DropdownMenuCheckboxItem";
import { DropdownMenuStandardItem } from "./components/DropdownMenuStandardItem";
import { useDropdownCheckbox } from "./components/DropdownMenuCheckboxItem/hooks/useDropdownCheckbox";

import "./scss/index.scss";

/**
 * Represents a dropdown menu component.
 *
 * @remarks
 * This component renders a dropdown menu with customizable options.
 *
 * @param options - An array of options to be displayed in the dropdown menu.
 * @param checkbox - A boolean indicating whether to render checkbox items in the menu.
 * @param lineBreak - A boolean indicating whether to apply line breaks between menu items.
 * @param rightAlignment - A boolean indicating whether to align the dropdown menu to the right.
 * @param selectedOption - The selected option value.
 * @param icon - An optional custom icon component to be displayed alongside the menu.
 * @param onClick - An optional callback function triggered when an option is clicked.
 * @returns A JSX element representing the dropdown menu.
 *
 * @example
 *  Basic usage of DropdownMenu with simple options
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 * />
 *
 * @example
 *  DropdownMenu with custom icon
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   icon={BiArrowBack}
 * />
 *
 * @example
 *  DropdownMenu with onClick handler
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 2", value: "value2", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 3", value: "value3", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) }
 *   ]}
 * />
 *
 * @example
 *  DropdownMenu with individual onClick handlers in options and a general onClick handler
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 2", value: "value2", onClick: ({ value, title }) => console.log(`Selected: ${title} (${value})`) },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   onClick={({ value, title }) => console.log(`Fallback selected: ${title} (${value})`)}
 * />
 *
 * @example
 *  DropdownMenu with right alignment
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   rightAlignment
 * />
 *
 * @example
 *  DropdownMenu with pre-selected option
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   selectedOption="value2" //Could be also the title value (Option 2)
 * />
 *
 * @example
 *  DropdownMenu with custom icon and customIcon
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1", customIcon: IconFromReactIcons },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   icon={DiApple}
 * />
 *
 * @example
 *  DropdownMenu with checkbox
 * <DropdownMenu
 *   options={[
 *     { title: "Option 1", value: "value1" },
 *     { title: "Option 2", value: "value2" },
 *     { title: "Option 3", value: "value3" }
 *   ]}
 *   checkbox
 * />
 */
export const DropdownMenu = <T,>({
  options,
  checkbox,
  lineBreak,
  className,
  title,
  ...props
}: DropdownMenuProps<T>) => {
  const checkboxProps = useDropdownCheckbox();

  return (
    <ul className={`drop-down-menu-list ${className}`}>
      {title ? <li className="drop-down-menu-list-title">{title}</li> : null}
      {options?.map((option: DropdownOption<T>, index: number) => (
        <li
          key={(option as DropdownOptionProps<T>).title || index}
          className={`drop-down-list-item ${lineBreak ? "line-break" : ""}`}
        >
          {checkbox ? (
            <DropdownMenuCheckboxItem
              {...{
                ...(option as DropdownOptionProps<T>),
                ...checkboxProps,
                value: (option as DropdownOptionProps<T>).value,
              }}
            />
          ) : (
            <DropdownMenuStandardItem
              {...{
                option,
                ...props,
              }}
            />
          )}
        </li>
      ))}
    </ul>
  );
};
