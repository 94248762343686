import { FC } from "react";

import { IDType } from "../types";

/**
 * Edit an order in the system
 *
 * @param ID - Order ID
 */
export const EditOrder: FC<IDType> = ({ recordID: orderID }) => (
  <a href={`/internal/order-crud-form/?order_pk=${orderID}&action=edit`}>
    Edit order
  </a>
);
