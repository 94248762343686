import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { UserAdministrationType } from "./types/userAdministration";
import { useFilters } from "@/hooks/useFilters";
import { useModal } from "@/components/Table/hooks/useModal";
import { GreenCircle } from "./assets/greenCircle";
import { GrayCircle } from "./assets/grayCircle";
import { EmailTemplate } from "@/components/Table/components/BaseTable/hooks/useTableRows/components/TableRowItem/components/TableRowActions/components/GenerateActions/components/GetAction/components/Email/template/EmailTemplate";
import { TableHeaders } from "@/components/Table/types/TableHeaders";

import "./scss/index.scss";

export const UserAdministration: FC = () => {
  const headers: TableHeaders = [
    {
      title: "NAME",
      apiParam: "full_name",
    },
    { title: "EMAIL", apiParam: "email" },
    { title: "FACILITY", apiParam: ["facility", "name"] },
    {
      title: "ROLE",
      apiParam: "role",
      type: "badge",
      properties: {
        badgeColor: {
          "group-admin": "success",
          "tech": "neutral",
          "admin": "neutral",
        },
      },
    },
    {
      title: "ACTIVE",
      apiParam: "is_active",
      type: "boolean",
      properties: {
        booleanType: {
          true: <GreenCircle />,
          false: <GrayCircle />,
        },
      },
    },
    {
      title: "PERMISSIONS",
      apiParam: "permission_groups",
      type: "array",
      properties: { key: "short_name" },
    },
  ];

  const url: string = getURL("api:internal-users-list");
  const filtersURL: string = getURL("api:internal-users-schema-options");

  const filterOptions = useFilters(filtersURL);

  const { modal, handleOpenModal } = useModal(EmailTemplate);

  return (
    <>
      {modal}
      <Table<UserAdministrationType>
        bulkActions={{
          primaryAction: {
            label: "Email",
            onClick: handleOpenModal,
            config: {
              type: "user",
            },
          },
        }}
        actions={[
          "impersonate",
          "edit_user_permissions",
          {
            "current": "email",
            config: {
              type: "user",
            },
          },
        ]}
        id={"user_id"}
        navbar={{
          header: "All Users",
          tooltip: {
            type: "?",
          },
          input: true,
          permissions_modal: true,
        }}
        filters={{
          ...filterOptions,
          config: {
            "Facility": "singleSelectSearchable",
          },
        }}
        headers={headers}
        url={url}
      />
    </>
  );
};
