import { useState, useEffect } from "react";

import { getURL } from "@/utils/getURL";

export const useOptions = (url: string, recordID: number) => {
  const [options, setOptions] = useState([]);

  const optionsURL: string = getURL(url, {
    pk: recordID.toString(),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(optionsURL);
        const { reason } = await response.json();
        setOptions(reason);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return options;
};
