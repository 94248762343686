import { DjangoObject } from "./types";

/**
 * Retrieves a global Django variable from the `window` object.
 *
 * This function attempts to fetch a variable from the global `window` object.
 * If the variable is not found, it returns `null` and logs a warning.
 * If the window object is not available, it returns `null`.
 *
 * @param {string} variableName - The name of the global variable to retrieve.
 * @default "DjangoMessages"
 *
 * @returns {string} - The value of the global variable or `null` if not found.
 */
export const getDjangoMessages = <T = Record<string, unknown>>(
  variableName: string = "DjangoMessages"
): DjangoObject<T> | null => {
  const djangoVariable: DjangoObject<T> = (window as any)[variableName];

  if (!window) return null;

  if (!djangoVariable) {
    console.warn(`Django message not found - ${variableName}`);
    return null;
  }

  return djangoVariable;
};
