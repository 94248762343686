import { FC } from "react";

import { IDType } from "../types";

/**
 * Modal for standing order activity
 *
 * @param standingOrderID - Standing Order ID
 */
export const StandingOrderActivity: FC<IDType> = ({ recordID: standingOrderID }) => (
  <a
    href="#"
    className="js-load_changelog_modal"
    data-url="/orders/standing-order-changelog/"
    data-id={standingOrderID}
  >
    Standing Order Activity
  </a>
);
