import { JSX } from "react";

import { RenderBooleanType } from "./types";
import { convertBooleanStringToNumber } from "./utils/convertBooleanStringToNumber";
import { Default } from "./components/Default";

export const renderBoolean = ({
  value,
  properties,
}: RenderBooleanType): JSX.Element | null => {
  const { className = "", booleanType } = properties ?? {};

  if (!booleanType) return <Default {...{ className, value }} />;

  const booleanTypeSerializer: Record<number, JSX.Element> =
    convertBooleanStringToNumber(booleanType);

  const valueToRender: JSX.Element | undefined = booleanTypeSerializer[+value];

  if (!valueToRender) return null;

  return <div className={className}>{valueToRender}</div>;
};
