import { EditFacility } from "./components/EditFacility";
import { Impersonate } from "./components/Impersonate";
import { SubmitRecall } from "./components/SubmitRecall";
import { SubmitForm } from "./components/SubmitForm";
import { ViewProfile } from "./components/ViewProfile";
import { EditUserPermissions } from "./components/EditUserPermissions";
import { Email } from "./components/Email";
import { ActionsMap, GetActionProps } from "./types";
import { OrderActivity } from "./components/orderActivity";
import { StandingOrderActivity } from "./components/standingOrderActivity";
import { ProgressOrder } from "./components/progressOrder";
import { EditOrder } from "./components/editOrder";
import { EditStandingOrder } from "./components/editStandingOrder";
import { PackageNotification } from "./components/packageNotification";
import { FlagOrder } from "./components/flagOrder";
import { CancelOrder } from "./components/cancelOrder";

export const GetAction = <T extends Record<string, any>>({
  action,
  record,
  config,
}: GetActionProps<T>): JSX.Element => {
  const recordID: number = record.id;

  const actions: ActionsMap = {
    order_activity: <OrderActivity {...{ recordID }} />,
    submit_recall: <SubmitRecall {...{ recordID }} />,
    submit_disposition_form: <SubmitForm {...{ recordID }} />,
    view_profile: <ViewProfile {...{ recordID }} />,
    edit_facility: <EditFacility {...{ recordID: record.facility_id }} />,
    impersonate: <Impersonate {...{ recordID }} />,
    edit_user_permissions: <EditUserPermissions {...{ record }} />,
    flag_order: <FlagOrder {...{ recordID }} />,
    email: (
      <Email
        {...{
          record,
          config,
        }}
      />
    ),
    standing_order_activity: <StandingOrderActivity {...{ recordID }} />,
    progress_order: <ProgressOrder {...{ recordID }} />,
    edit_order: <EditOrder {...{ recordID }} />,
    edit_standing_order: <EditStandingOrder {...{ recordID }} />,
    package_notification: <PackageNotification {...{ recordID }} />,
    cancel_order: <CancelOrder {...{ recordID }} />,
  };

  return actions[action];
};
